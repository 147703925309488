import * as React from "react"
import Seo from "../components/seo"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import Layout from "../components/layout2023"
import FullServiceTeamV2 from "../componentsv2/full-service-teamv2/full-service-teamv2"
const FullServiceTeam = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024`}>
    <Seo
      title="Conversion Rate Optimization Experts | 30% Lift Guaranteed"
      description="Guaranteed 30% lift with conversion rate optimization experts managing research, copy, design, development, analytics, and project management."
      showDefaultTitle={false}
    />
    <FullServiceTeamV2 />
    <FooterV2 version="v2" />
  </Layout>
)

export default FullServiceTeam
